import React, { useEffect } from 'react'
import Nav from '../components/Nav/Nav'
import { useAuth,  } from '../contexts/AuthContext'
import '../App.css';
import { useNavigate } from 'react-router-dom'

function Dashboard() {
  const navigate = useNavigate()
    const { getRestaurants ,listRestaurants, currentUser} = useAuth()
    const MyRestaurant = listRestaurants?.filter(function(restaurant) { return restaurant });


  useEffect(() => { 
   currentUser && getRestaurants()
      // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [currentUser]); 

  return (
    <div>
      <Nav dashboard></Nav>
      <div className='wrapper-container'>
      <div className='wrapper-content'>
         <div className='title-wrapper'>
          <p>Mes restaurants</p>

          </div>
                { MyRestaurant?.length < 1 && <div className='not-found'><p>Aucun restaurant.</p></div>}

      <div className='wrapper'>




     { MyRestaurant?.map((restaurant, index) => {
         return(
            <div key={index}>  
        <div className={`card-item  ${index === (MyRestaurant.length - 1) ? '' : 'border'}`} >
       
        <div className='title-wrap'>
        <img className='profile' alt='' src={restaurant.photo}  />
             <div>
           <p className='title'>{restaurant.name}</p>
         <p className='sub-title'>{restaurant.adress}</p>     
             </div>
 
        
        </div>
      
        
        <p className='link'  onClick={() => navigate(`/restaurant/${restaurant.id}`)}>Gérer</p>
      </div>

            
            </div>
         )
         })}

       
      </div>
      </div>
       
      </div>
      </div>
  )
}

export default Dashboard