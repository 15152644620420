import React, { useState, useEffect } from 'react';
import Nav from '../components/Nav/Nav'
import { useAuth,  } from '../contexts/AuthContext';
import '../App.css';
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router-dom"
import { ArrowLeftOutlined, LoadingOutlined} from '@ant-design/icons';



function NewCategory() {
  const { postCategory, isLoading,categoryM ,listCategories ,getCategories, t, translated, languages} = useAuth()
  const { id } = useParams();
  const navigate = useNavigate()

  const [name, setName] = useState('');
  const [selectedOption, setSelectedOption] = useState('visible');
  const MyCategories = listCategories?.filter(function(categorie) { return categorie.restaurant === id });
  const [checkboxState, setCheckboxState] = useState({});


  useEffect(() => { 
    getCategories(id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    postCategory(name, id, selectedOption,  (MyCategories?.length > 1 ?  (5 * MyCategories?.length) -5 : MyCategories?.length) , translated);
    setName('');
    setSelectedOption('visible');
    setCheckboxState({})
    getCategories(id);
  };

  const handleCheckboxChange = (code) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [code]: !prevState[code],
    }));
    t(name,code )
    };

  return (
    <div>
      <Nav></Nav>
      <form  onSubmit={handleSubmit} className='wrapper-container'>
      <div className='wrapper-content'>
      <div className='title-wrapper'>
      <div className='title-back'>
         <ArrowLeftOutlined onClick={() => navigate(-1)}  className='back'/>
            <p>Nouvelle Catégorie</p>
            </div> 
          </div>
      <div className='wrapper-form'>

        <div className='input-container'>
        <label htmlFor="name">Nom de la catégorie</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

        </div>



        <div className='input-container'>
        <label htmlFor="selectOption">Status</label>
          <select
            id="selectOption"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            required
          >
            <option value="visible">Visible</option>
            <option value="masqué">Masqué</option>
          </select>
        </div>

        {   name &&  <div  className='input-container'>
        <label htmlFor="checkbox">Traduction</label>

        <div className='checkbox-container'>


        {languages.map((language, index) => (
  <label key={index}>
    <input
      type="checkbox"
      checked={checkboxState[language.code] || false}
      onChange={() => handleCheckboxChange(language.code)}
    />
    {language.name}
  </label>
))}
<div>{Object.keys(translated)?.length}</div>

 </div>

</div>}

       
       <div className='validation-container'>

       <button className='button save' type="submit">{ isLoading ? <LoadingOutlined  spin /> : 'Enregistrer'}
</button>
{ categoryM && <div className='delete info'>{categoryM}</div>}

       </div>
       
      </div>
      </div>
       
      </form>
      </div>
  )
}

export default NewCategory


