import React, {  useState, useEffect } from 'react';
import Nav from '../components/Nav/Nav'
import {ArrowLeftOutlined  , LoadingOutlined} from '@ant-design/icons';
import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import { useAuth,  } from '../contexts/AuthContext';
import imageCompression from 'browser-image-compression';
import '../App.css';

function Informations() {
  const {updateRestaurant,isLoading,restaurantM , languages
    ,currentRestaurant ,currentUser, photoUrl,getRestaurant, addPhoto,  t, translated} = useAuth()
    const navigate = useNavigate()
    const {id} = useParams();

    const [name, setName] = useState('');
    const [adress, setAdress] = useState('');
    const [type, setType] = useState('');
    const [hours, setHours] = useState('');
    const [review, setReview] = useState('');
    const [photo, setPhoto] = useState('');
    const [status, setStatus] = useState('visible');
    const [imagePreview, setImagePreview] = useState('');
    const [checkboxState, setCheckboxState] = useState({});
    const [defaultL, setDefaultL] = useState(true);
    const [social, setSocial] = useState({ google: '', instagram: '', tiktok: "" });


    useEffect(() => { 
      getRestaurant(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
       }, []);

    
        useEffect(() => {
          currentUser && currentRestaurant &&  currentRestaurant.owner !== currentUser.uid && navigate("/")
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [currentUser, currentRestaurant]);

       useEffect(() => { 

        console.log(photo)  
         }, [photo]);




       useEffect(() => { 
        if(currentRestaurant ) {
         setName(currentRestaurant.name)
         setAdress(currentRestaurant.adress)
          setType(currentRestaurant.type)
          setHours(currentRestaurant.hours)
          setPhoto(currentRestaurant.photo)
          setStatus(currentRestaurant.status)
          setReview(currentRestaurant.review)
          setDefaultL(currentRestaurant.defaultL)
          currentRestaurant?.social &&  setSocial(currentRestaurant.social)
        }}, [currentRestaurant]); 
 

       useEffect(() => {
       
      photoUrl && updateRestaurant(id,currentUser?.uid, name, adress,type,  hours, review, photoUrl, status,defaultL, type !== currentRestaurant.type ? translated : currentRestaurant.translated,
        currentRestaurant?.social && currentRestaurant.social !== social ? social : currentRestaurant.social)
 
              // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [photoUrl]);


    const handleSubmit = (event) => {
         event.preventDefault();
     if( photo !== currentRestaurant.photo){
      addPhoto(photo)
     } else{
      updateRestaurant(id,currentUser?.uid, name, adress,type,  hours, review, photo, status,defaultL, type !== currentRestaurant.type ? translated : currentRestaurant.translated,
        currentRestaurant?.social ? ( currentRestaurant.social !== social ? social : currentRestaurant.social) : social )
     }
    };


    const handleChangeSocial = (e) => {
      const { name, value } = e.target;
      setSocial((prevSocial) => ({
        ...prevSocial,
        [name]: value,
      }));
    };


    const handleCheckboxChange = (code) => {
      setCheckboxState((prevState) => ({
        ...prevState,
        [code]: !prevState[code],
      }));
      t(type,code )
      };

      const handleFileChange = (event) => {
        const imageFile = event.target.files[0];
        console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
      
        var options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1000,
          useWebWorker: true
        }
        imageCompression(imageFile, options)
        .then(function (compressedFile) {
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    
          setPhoto(compressedFile); // write your own logic
        })
        .catch(function (error) {
          console.log(error.message);
        });
        
  
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
    
        if (imageFile) {
          reader.readAsDataURL(imageFile);
        } else {
          setImagePreview('');
        }
      };
  

  return (
    <div>
      <Nav></Nav>
      <form onSubmit={handleSubmit} className='wrapper-container'>
      <div className='wrapper-content'>
      <div className='title-wrapper'>
      <div className='title-back'>
         <ArrowLeftOutlined onClick={() => navigate(-1)}  className='back'/>
            <p>Informations</p>
            </div>  
          </div>
      <div className='wrapper-form'>

      <div className='input-container'>
        <label htmlFor="name">Nom du restaurant</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>


        <div className='input-container'>
        <label htmlFor="adress">Adresse</label>
          <input
            type="text"
            id="adress"
            value={adress}
            onChange={(e) => setAdress(e.target.value)}
            required
          />
        </div>



        <div className='input-container'>
        <label htmlFor="type">Type de restaurant</label>
          <input
            type="text"
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          />
        </div>




        <div className='input-container'>
              <label htmlFor="hours">Positionnement</label>
              <select
               id="hours"
               value={hours}
               onChange={(e) => setHours(e.target.value)}
               required
              >
                <option value="">Choisir</option>
                <option value="Prix bas">Prix bas</option>
                <option value="Prix moyens">Prix moyens</option>
                <option value="Prix élevés">Prix élevés</option>

              </select>
            </div>



        <div className='input-container'>
        <label htmlFor="review">Lien pour avis</label>
          <input
            type="text"
            id="review"
            value={review}
            onChange={(e) => setReview(e.target.value)}
            required
          />
        </div>




        <div className='input-container' style={{height:'auto'}}>
    <label htmlFor="photo walpaper">Photo</label>

     

     
          <div className='photo-container'>
            <div style={{ maxWidth: '100%', maxHeight: '110px', display:'flex' }} >
            <img src={ imagePreview ? imagePreview : photo} alt="Selected" style={{ maxWidth: '100%', maxHeight: '110px' }} />
            <input
       className="inputfile"
            type="file"
            id="fileInput"
            accept="image/*"
            onChange={handleFileChange}
          />
          </div>
          </div>
      

        </div>

        <div className="option-wrapper">
              <div className='input-container'>
                <label htmlFor="google">Reseaux sociaux</label>
                <div className='option-container'>
                  <input
                    type="text"
                    className='input-social'
                    name="google"
                    id='google'
                    value={social.google}
                    onChange={handleChangeSocial}
                    placeholder='Facebook'
                  />

                  <input
                    type="text"
                    className='input-social'
                    name="instagram"
                    value={social.instagram}
                    onChange={handleChangeSocial}
                    placeholder='Instagram'
                  />

                  <input
                    type="text"
                    className='input-social'
                    name="tiktok"
                    value={social.tiktok}
                    onChange={handleChangeSocial}
                    placeholder='Tiktok'
                  />
                </div>
              </div>
            </div>






        <div className='input-container'>
        <label htmlFor="status">Status</label>
          <select
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value="visible">Visible</option>
            <option value="hidden">Masqué</option>
          </select>
        </div>



        <div className='input-container'>
        <label htmlFor="defaultL">Français par defaut</label>
          <select
            id="defaultL"
            value={defaultL}
            onChange={(e) => setDefaultL(e.target.value)}
            required
          >
            <option value={true}>Oui</option>
            <option value={false}>Non</option>
          </select>
        </div>


        {   type !== currentRestaurant?.type &&  <div  className='input-container'>
        <label htmlFor="checkbox">Traduction</label>

        <div className='checkbox-container'>


        {languages.map((language, index) => (
  <label key={index}>
    <input
      type="checkbox"
      checked={checkboxState[language.code] || false}
      onChange={() => handleCheckboxChange(language.code)}
    />
    {language.name}
  </label>
))}







        </div>

</div>}

       
        <div className='validation-container'>

       <button className='button save' type="submit">{ isLoading ? <LoadingOutlined  spin /> : 'Enregistrer'}
</button>
{ restaurantM && <div className='delete info'>{restaurantM}</div>}

       </div>
       
      </div>
      </div>
       
      </form>
      </div>
  )
}

export default Informations


