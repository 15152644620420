import React, { Fragment, useEffect } from 'react'
import Nav from '../components/Nav/Nav'
import {ArrowLeftOutlined , FormOutlined   } from '@ant-design/icons';
import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import { useAuth,  } from '../contexts/AuthContext'
import '../App.css';

function Products() {
  const navigate = useNavigate()
  const {id} = useParams();
  const { listCategories ,getCategories,getProducts, listProducts, currentRestaurant, currentUser, getRestaurant} = useAuth()
  const MyProducts = listProducts?.filter(function(product) { return product.restaurant === id });

  useEffect(() => { 
    getCategories(id) 
    getProducts(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

     useEffect(() => {
      getRestaurant(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
  
      useEffect(() => {
        currentUser && currentRestaurant &&  currentRestaurant.owner !== currentUser.uid && navigate("/")
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentUser, currentRestaurant]);

  return (
    <div>
      <Nav></Nav>
      <div className='wrapper-container'>
      <div className='wrapper-content'>
      <div className='title-wrapper'>
      <div className='title-back'>
         <ArrowLeftOutlined onClick={() => navigate(-1)}  className='back'/>
            <p>Produits {MyProducts && `(${MyProducts?.length})`} </p>
            </div> 
          <button onClick={() => navigate(`/restaurant/${id}/products/new`)} className='button'>Ajouter un produit</button>
          </div>


          { MyProducts?.length < 1 && <div className='not-found'><p>Aucun produit.</p></div>}


      <div className='wrapper'>
      {MyProducts?.map((product, index) => { 
        return(
            <div key={index}>
        <div className={`card-item  ${index === (MyProducts.length - 1) ? '' : 'border'}`} >
       
          <div className='title-wrap max-product'>
          <img className={`profile  ${!product.picture && 'bg-grey'}`}  src={product.picture} alt="" />
               <div>
             <p className='title'>{product.name}</p>
           <p className='sub-title sub-title-price '>{product.price} €</p>     
               </div>
          </div>



          <div className='desc'><p>{product.description}</p></div>


                    {listCategories?.map((category, index) => { 
        return(
             <Fragment key={index}>       
             {category.id === product.category &&
           <p className='category-product'>{category.name}</p>}
             </Fragment>
    
        )})}
        
          <FormOutlined className='tool' onClick={() => navigate(`/restaurant/${id}/products/product/${product.id}`)}/>
        </div>
        </div>
        )
       })}
      



       
      </div>
      </div>
       
      </div>
      </div>
  )
}

export default Products


