import React, { useEffect } from 'react'
import Nav from '../components/Nav/Nav'
import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import { FormOutlined , ArrowLeftOutlined  } from '@ant-design/icons';
import { useAuth,  } from '../contexts/AuthContext'
import '../App.css';

function Categories() {
  const navigate = useNavigate()
  const {id} = useParams();
  const { listCategories ,getCategories, currentUser, getRestaurant,currentRestaurant} = useAuth()
  const MyCategories = listCategories?.filter(function(categorie) { return categorie.restaurant === id });


  useEffect(() => { 
    getCategories(id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

     useEffect(() => {
      getRestaurant(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
  
      useEffect(() => {
        currentUser && currentRestaurant &&  currentRestaurant.owner !== currentUser.uid && navigate("/")
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentUser, currentRestaurant]);

  return (
    <div>
      <Nav></Nav>
      <div className='wrapper-container'>
      <div className='wrapper-content'>
      <div className='title-wrapper'>
         <div className='title-back'>
         <ArrowLeftOutlined onClick={() => navigate(-1)}  className='back'/>
            <p>Catégories {MyCategories && `(${MyCategories?.length})`} </p>
            </div> 
          <button onClick={() => navigate(`/restaurant/${id}/categories/new`)} className='button'>Ajouter une catégorie</button>
          </div>

       { MyCategories?.length < 1 && <div className='not-found'><p>Aucune catégorie.</p></div>}

      <div className='wrapper'>
  
     {MyCategories?.map((categorie, index) => {
         return(
           
        <div key={index} className={`card-item  ${index === (MyCategories.length - 1) ? '' : 'border'}`} >
       
        <div className='title-wrap'>
        <p className='number'>{categorie.position}</p>
             <div>
           <p>{categorie.name}</p>   
             </div>
 
        
        </div>
 
      
        <FormOutlined className='tool' onClick={() => navigate(`/restaurant/${id}/categories/category/${categorie.id}`)} />
      </div>
         )
         })}
       
      </div>
      </div>
       
      </div>
      </div>
  )
}

export default Categories



