import './App.css';
import React from 'react'
import {BrowserRouter as Router, Routes, Route, Navigate, Outlet} from "react-router-dom"
import { AuthProvider } from './contexts/AuthContext';
import ScrollToTop from './components/ScrollToTop';

import Menu from './pages/Menu';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Restaurant from './pages/Restaurant';
import Products from './pages/Products';
import Categories from './pages/Categories';
import NewProduct from './pages/NewProduct';
import NewCategory from './pages/NewCategory';
import Informations from './pages/Informations';
import Product from './pages/Product';
import Category from './pages/Category';
import NewRestaurant from './pages/NewRestaurant';
import Terms from './pages/Terms';


function App() {

  // Désactive console.log en production
if (process.env.NODE_ENV === 'production') {
  console.log = function() {};
}
    

  return (
    <AuthProvider>
      <div style={{ width: '100%' }}>
        <Router>
        <ScrollToTop />
          <Routes>
            <Route exact path="/restaurant/:id/menu" element={<Menu/>} />
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/terms" element={<Terms/>} />
            <Route path="*" element={<Home/>} />
            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/dashboard" element={<Dashboard/>} />
            <Route exact path="/restaurant/:id" element={<Restaurant/>} />
            <Route exact path="/restaurant/:id/products" element={<Products/>} />
            <Route exact path="/restaurant/:id/categories" element={<Categories/>} />
            <Route exact path="/restaurant/:id/products/new" element={<NewProduct/>} />
            <Route exact path="/restaurant/:id/categories/new" element={<NewCategory/>} />
            <Route exact path="/restaurant/:id/informations" element={<Informations/>} />
            <Route exact path="/restaurant/:id/products/product/:id" element={<Product/>} />
            <Route exact path="/restaurant/:id/categories/category/:id" element={<Category/>} />
            <Route exact path="/new-restaurant" element={<NewRestaurant/>} />

            <Route exact path="/new-restaurant" element={<PrivateRoute/>}>
            <Route exact path="/new-restaurant" element={<NewRestaurant/>} />
            </Route>

            <Route exact path="/restaurant/:id/categories/category" element={<PrivateRoute/>}>
            <Route exact path="/restaurant/:id/categories/category" element={<Category/>} />
            </Route>

            <Route exact path="/restaurant/:id/products/product" element={<PrivateRoute/>}>
            <Route exact path="/restaurant/:id/products/product" element={<Product/>} />
            </Route>

            <Route exact path="/restaurant/:id/informations" element={<PrivateRoute/>}>
            <Route exact path="/restaurant/:id/informations" element={<Informations/>} />
            </Route>
            

            <Route exact path="/restaurant/:id/categories/new" element={<PrivateRoute/>}>
            <Route exact path="/restaurant/:id/categories/new" element={<NewCategory/>} />
            </Route>

            <Route exact path="/restaurant/:id/products/new" element={<PrivateRoute/>}>
            <Route exact path="/restaurant/:id/products/new" element={<NewProduct/>} />
            </Route>

            <Route exact path="/restaurant/:id/categories" element={<PrivateRoute/>}>
            <Route exact path="/restaurant/:id/categories" element={<Categories/>} />
            </Route>

     
            <Route exact path="/dashboard" element={<PrivateRoute/>}>
            <Route exact path="/dashboard" element={<Dashboard/>} />
            </Route>

            <Route exact path="/restaurant/:id" element={<PrivateRoute/>}>
            <Route exact path="/restaurant/:id" element={<Restaurant/>} />
            </Route>

            <Route exact path="/restaurant/:id/products" element={<PrivateRoute/>}>
            <Route exact path="/restaurant/:id/products" element={<Products/>} />
            </Route>
            <Route exact path="/login" element={<PublicRoute/>}>
            <Route path="/login" element={<Login />}   />
            </Route>

          </Routes>
        </Router>

      </div>
    </AuthProvider>

  );
}

export default App;

const PrivateRoute = () => {
  const currentUser = localStorage.getItem('connected');
    return currentUser ? <Outlet /> : <Navigate to="/" />;
  }
  
  const PublicRoute = () => {
    const currentUser = localStorage.getItem('connected');
    return !currentUser ? <Outlet /> : <Navigate to="/dashboard" />;
    }


